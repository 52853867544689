/* 
* dashboard.directive.js
* 1-9-2021 - Jelmer Jellema - Spin in het Web B.V.
* Het docentendashboard. Min of meer keurig afgesplitst.
* Is standaard dicht, en dan ook echt leeg op de directive na.
* Niet echt een helemaal apart in te zetten directive, gezien de styling
*/

dynalearn.directive('dashboard', [
    'sihwlog', 'elementService',
    function (sihwlog, elementService) {
        const rebuildtimeout = 10000; //tijd voordat rebuild plaatsvindt
        let log = sihwlog.logLevel('debug');

        return {
            restrict: 'E',
            templateUrl: 'app/directives/dashboard/dashboard.html',
            scope: {
                api: '=', //exposable api
                laatMeekijken: '&onMeekijken' //<dashboard on-meekijken=functie<model>>
            },

            //controller, om ook wat zaken te exposen
            controller: ['$scope', '$timeout', '$q', 'sihwWait', 'api',
                function ($scope, $timeout, $q, sihwWait, api) {
                    //intern
                    let _rebuildTimer = null; //$timeout voor herbouwen
                    let _acties = []; //onze actionlog-actiescount
                    let _types = []; //onze actionlog-typecount
                    let _live = []; //de livemodellen die zijn binnengekomen via de subscription
                    let _barchart = null; //gezet in $scope.onChartCreate;
                    let _donutchart = null; //gezet in $scope.onChartCreate;
                    let alleseries = {}; //seriekey -> serie. Wordt gevuld op basis van de const balken hieronder

                    //types
                    const _actietypes = {
                        'create': ['create'],
                        'modify': ['modify'],
                        'delete': ['delete'],
                        'model': ['copymodel', 'newmodel', 'modifymodel'],
                        'sim': ['start_sim', 'stop_sim', 'sim_nextstep', 'sim_select', 'sim_multiselect', 'sim_setselected', 'sim_toggle_ih', 'sim_toggle_vh'],
                        'undoredo': ['undo', 'redo'],
                        'condities': ['cond_new', 'cond_add', 'cond_reset', 'cond_set', 'cond_delete', 'cond_rename', 'cond_view'],
                        'fouten': ['help', 'foutselect', 'simfb', 'simfb_select', 'simfb_show'],
                        '_skip': ['init_app', 'errormodel', 'play_video', 'cancel_video']
                    }

                    /**
                     * Voor de elementen: key is serienaam. Value is null (dan is de key het elementtype) of een array met elementtypen. Als een genoemd element in de log (een subtype van) het gegeven type is, telt het voor die serie
                     * @private
                     */
                    const _elementtypes = {
                        entity: null,
                        agent: null,
                        assumption: null,
                        quantity: null,
                        quantity_space: ['quantity_space', 'quantity_space_element'],
                        attribute: null,
                        configuration: null,
                        value: null,
                        correspondence: null,
                        q_exo: ['q_exo', 'quantity_allvalues'],
                        ineq: null,
                        calc: null,
                        proportionality: null,
                        influence: null,
                        _skip: ['model', 'sim', 'app', 'transitie', 'state']
                    };
                    /**
                     * De verschillende balken die uit en aan kunnen. Daarbinnen hun series. Daarin de basis hidden. De hidden wordt meegenomen in de override, en dat object wordt uiteindelijk aangepast op basis van de hidden per serie en de model.balken (zie updateBalken)
                     * We doen dit als $scope.balken, zodat we er bij kunnen (ook aan/uit zetten hidden per serie)
                     * Kleuren: coolors.co
                     * @type {{}}
                     */
                    const balken = $scope.balken = {
                        acties: {
                            //de series in acties
                            //dit wordt hieronder uitgewerkt naar andere zaken en overrides
                            //speciaal is tekstwit (boolean): als gegeven dan is de voorgrond in de legenda wit ipv zwart
                            create: {
                                color: 'rgba(126,189,194,0.75)',
                                spanclass: "fa fa-plus",
                                title: 'Action: create'
                            },
                            modify: {
                                color: 'rgba(226,115,150,0.75)',
                                spanclass: "fa fa-pencil",
                                title: 'Action: modify',
                                // tekstwit: true
                            },
                            'delete': {
                                color: 'rgba(255,229,217,0.75)',
                                spanclass: "fa fa-trash",
                                title: 'Action: delete',
                            },
                            undoredo: {
                                color: 'rgba(251,186,114,0.75)',
                                hidden: true,
                                spanclass: 'fa fa-undo',
                                title: 'Action: undo/redo'
                            },
                            condities: {
                                color: 'rgba(255,190,11,0.75)',
                                hidden: true,
                                spanclass: 'fa fa-code-fork',
                                title: 'Action: condities'
                            },
                            model: {
                                color: 'rgba(219,213,110,0.75)',
                                hidden: true,
                                spanclass: 'fa fa-file',
                                title: 'Action: model'
                            },
                            sim: {
                                color: 'rgba(240,207,101,0.75)',
                                hidden: true,
                                spanclass: 'fa fa-chevron-right',
                                title: 'Action: simulate'
                            },
                            fouten: {
                                color: 'rgba(255,125,0,0.84)',
                                hidden: true,
                                spanclass: 'fa fa-question-circle-o',
                                title: 'Action: fouten'
                            },
                            rest: {
                                color: 'rgba(84,84,84,0.75)',
                                hidden: true,
                                tekstwit: true,
                                spanclass: 'fa fa-ellipsis-h',
                                title: 'Action: rest'
                            }
                        },
                        elementen: {
                            entity: {
                                color: 'rgba(85,5,39,0.75)',
                                title: 'Acties: Entiteit',
                                svg: true //zie onder wij uitwerken
                            },
                            agent: {
                                color: 'rgba(68,5,85,0.75)',
                                title: 'Acties: Agent',
                                svg: true
                            },
                            assumption: {
                                color: 'rgba(44,5,85,0.75)',
                                title: 'Acties: Assumptie',
                                svg: true
                            },
                            quantity: {
                                color: 'rgba(104,142,38,0.75)',
                                svg: true,
                                title: 'Acties: Grootheid'
                            },
                            quantity_space: {
                                color: 'rgba(188,189,192,0.75)',
                                svg: true,
                                title: 'Acties: Waardenbereik'
                            },
                            attribute: {
                                color: 'rgba(250,166,19,0.75)',
                                svg: true,
                                title: 'Acties: Attribuut'
                            },
                            configuration: {
                                color: 'rgba(244,71,8,0.75)',
                                svg: true,
                                title: 'Acties: Configuratie'
                            },
                            value: {
                                color: 'rgba(161,7,2,0.75)',
                                svg: true,
                                title: 'Acties: Waarde'
                            },
                            correspondence: {
                                color: 'rgba(161,74,2,0.75)',
                                svg: true,
                                title: 'Acties: Correspondentie'
                            },
                            q_exo: {
                                color: 'rgba(0,148,198,0.75)',
                                svg: true,
                                title: 'Acties: Exogeen'
                            },
                            ineq: {
                                color: 'rgba(245,26,164,0.75)',
                                svg: true,
                                title: 'Acties: Ongelijkheid'
                            },
                            calc: {
                                color: 'rgba(188,189,192,0.75)',
                                svg: true,
                                title: 'Acties: Calculus'
                            },
                            proportionality: {
                                color: 'rgba(60,219,211,0.75)',
                                svg: true,
                                title: 'Acties: Proportionaliteit'
                            },
                            influence: {
                                color: 'rgba(60,86,219,0.75)',
                                svg: true,
                                title: 'Acties: Invloed'
                            }
                        },
                        norm: {
                            entity_goed: {
                                color: 'rgba(85,5,39,0.75)',
                                svg: 'entity',
                                title: 'Norm: Entiteit',
                                tooltiplabel: 'Norm: Entiteit goed'
                            },
                            entity_fout: {
                                color: 'rgba(85,5,39,0.5)',
                                tooltiplabel: 'Norm: Entiteit fout', tekstwit: true,
                                volgt: 'entity_goed' //tegelijk uit en aan met deze, geen eigen button
                            },
                            entity_missend: {
                                color: 'rgba(85,5,39,0.2)',
                                tooltiplabel: 'Norm: Entiteit missend', volgt: 'entity_goed'
                            },

                            configuration_goed: {
                                color: 'rgba(244,71,8,0.75)', tekstwit: true, button: 'Configuration',
                                svg: 'configuration',
                                title: 'Norm: Configuratie',
                                tooltiplabel: 'Norm: Configuratie goed'
                            },
                            configuration_fout: {
                                color: 'rgba(244,71,8,0.5)',
                                tooltiplabel: 'Norm: Configuratie fout',
                                volgt: 'configuration_goed'
                            },
                            configuration_missend: {
                                color: 'rgba(244,71,8,0.2)', volgt: 'configuration_goed',
                                tooltiplabel: 'Norm: Configuratie missend'
                            },

                            quantity_goed: {
                                color: 'rgba(104,142,38,0.75)',
                                svg: 'quantity',
                                title: 'Norm: Grootheid',
                                tooltiplabel: 'Norm: Grootheid goed'
                            },
                            quantity_fout: {
                                color: 'rgba(104,142,38,0.5)', volgt: 'quantity_goed',
                                tooltiplabel: 'Norm: Grootheid fout'
                            },
                            quantity_missend: {
                                color: 'rgba(104,142,38,0.2)', volgt: 'quantity_goed',
                                tooltiplabel: 'Norm: Grootheid missend'
                            },

                            ineq_goed: {
                                color: 'rgba(245,26,164,0.75)', button: 'Ineq',
                                svg: 'ineq',
                                title: 'Norm: Ongelijkheid',
                                tooltiplabel: 'Norm: Ongelijkheid goed'
                            },
                            ineq_fout: {
                                color: 'rgba(245,26,164,0.5)', volgt: 'ineq_goed',
                                tooltiplabel: 'Norm: Ongelijkheid fout'
                            },
                            ineq_missend: {
                                color: 'rgba(245,26,164,0.2)', volgt: 'ineq_goed',
                                tooltiplabel: 'Norm: Ongelijkheid missend'
                            },

                            correspondence_goed: {
                                color: 'rgba(161,7,2,0.75)', button: 'Corr',
                                svg: 'correspondence',
                                title: 'Norm: Correspondentie',
                                tooltiplabel: 'Norm: Correspondentie goed'
                            },
                            correspondence_fout: {
                                color: 'rgba(161,7,2,0.5)', volgt: 'correspondence_goed',
                                tooltiplabel: 'Norm: Correspondentie fout'
                            },
                            correspondence_missend: {
                                color: 'rgba(161,7,2,0.2)', volgt: 'correspondence_goed',
                                tooltiplabel: 'Norm: Correspondentie missend'
                            },

                            proportionality_goed: {
                                color: 'rgba(60,219,211,0.75)',
                                svg: 'proportionality',
                                title: 'Norm: Proportionaliteit',
                                tooltiplabel: 'Norm: Proportionaliteit goed'
                            },
                            proportionality_fout: {
                                color: 'rgba(60,219,211,0.5)', volgt: 'proportionality_goed',
                                tooltiplabel: 'Norm: Proportionaliteit fout'
                            },
                            proportionality_missend: {
                                color: 'rgba(60,219,211,0.2)', volgt: 'proportionality_goed',
                                tooltiplabel: 'Norm: Proportionaliteit missend'
                            },
                            influence_goed: {
                                color: 'rgba(60,86,219,0.75)',
                                svg: 'influence',
                                title: 'Norm: Proportionaliteit',
                                tooltiplabel: 'Norm: Invloed goed'
                            },
                            influence_fout: {
                                color: 'rgba(60,86,219,0.5)',
                                volgt: 'influence_goed',
                                tooltiplabel: 'Norm: Invloed fout'
                            },
                            influence_missend: {
                                color: 'rgba(60,86,219,0.2)',
                                volgt: 'influence_goed',
                                tooltiplabel: 'Norm: Invloed missend'
                            }
                        }
                    };

                    //norm niet in donut
                    for (let key of Object.keys(balken.norm)) {
                        balken.norm[key].nietindonut = true;
                    }

                    //overrides per balk
                    const _balkoverride = {
                        acties: {
                            borderColor: 'rgba(100,100,100,0.6)'
                        },
                        elementen: {
                            borderColor: 'rgba(52,133,52,0.6)'
                        },
                        norm: {
                            borderColor: 'rgba(200,0,0,0.6)'

                        }
                    };

                    //eigen opties
                    $scope.opties = {
                        barwidth: 40 //pixels breedte per model (niet per bar)
                    }
                    $scope.chartWidth = 200; //wordt berekend

                    //uitbouwen informatie
                    for (let balkkey of Object.keys(balken)
                        ) {
                        let balk = balken[balkkey];
                        for (let seriekey of Object.keys(balk)) {
                            let serie = balk[seriekey];
                            alleseries[seriekey] = serie; //serielookup
                            serie.seriekey = seriekey;
                            //maak de override
                            serie.volgers = [];
                            if (serie.spanclass) {
                                //dus een span met class
                                serie.button = `<span class="${serie.spanclass}"></span>`;
                            } else if (serie.svg) {
                                //we zetten er een svg-div in. Met als class de opgegeven naam of als die true is de seriekey
                                serie.button = `<div class="svg ${serie.svg === true ? seriekey : serie.svg}"></div>`
                            }
                            serie.override = Object.assign({}, _balkoverride[balkkey], {
                                borderWidth: 2,
                                maxBarThickness: $scope.opties.barwidth,
                                minBarLength: 0.5,
                                barPercentage: 0.8,
                                categoryPercentage: 0.9,
                                hidden: serie.hidden,
                                backgroundColor: serie.color,
                                stack: balkkey,
                                yAxisID: balkkey
                            });
                        }
                        //en nog een keer als er een volgt is
                        for (let serie of Object.values(balken[balkkey])) {
                            if (serie.volgt) {
                                let gevolgde = balken[balkkey][serie.volgt];
                                gevolgde.volgers.push(serie); //zodat we bij toggle andersom kunnen zoeken
                                serie.hidden = serie.override.hidden = gevolgde.hidden;
                            }
                        }
                    }

                    //scope
                    $scope._open = false; //zijn we zichtbaar?
                    $scope.models = {}; //info per model, eventueel gecached
                    $scope.dataLeeg = true; //er is nog geen data
                    $scope.telling = {
                        models: [], //de modelkeys die de volgorde bepalen, voor indexeren e.d.
                        chartmodels: [], //modelkeys die feitelijk in de chart zitten (na filter)
                        labels: [],
                        live: [], //boolean per model
                        totalen: {}, //totalen per serie
                        data: [],
                        series: [], //zie onder deze geven we weer in de telling per model
                        colors: [], //zie onder
                        override: [], //zie onder
                        donutdata: [], //de donutdata, zie updateBalken
                        donutseries: [],
                        donutcolors: [],
                        donutlabels: [] //de donutlabels
                    };
                    /**
                     * Een geselecteerd detailmodel.Als gezet wijzigt de layout. Zie setDetail
                     */
                    $scope.detail = null;
                    $scope.detailImg = null; //url naar detail in detailmodus
                    $scope.updateFlag = Date.now(); //werken we bij elke update bij. Voor reload e.d.


                    //boldmaker, moet hier en niet in een config, omdat we $scope nodig hebben
                    if (!Chart.plugins.getAll().some(p => p.id === 'livemodels')) {
                        Chart.plugins.register({
                            id: 'livemodels',
                            beforeDraw: function ({chart}) {
                                try {
                                    let scale = chart.scales['x-axis-0'];
                                    //vergelijken met $scope.live
                                    let i = 0;
                                    for (let tick of (scale?._ticks || [])) {
                                        tick.major = !!(($scope.model.state !== 'live') && _live && _live.includes($scope.telling.chartmodels[i]));
                                        i++;
                                    }
                                } catch (_e) {
                                    //laat maar
                                }
                            }
                        });
                    }
                    $scope.chartoptions = {
                        model_bar: {
                            //opties voor de grafiek per model
                            responsive: true,
                            maintainAspectRatio: false, //hoeft niet, als het maar past
                            elements: {
                                rectangle: {
                                    borderWidth: 0
                                }
                            },
                            legend: {
                                display: false, //alleen voor testen
                                position: 'left'
                            },
                            tooltips: {
                                mode: 'nearest',
                                intersect: false,
                                callbacks: {
                                    label: (tooltipItem) => {
                                        let seriekey = $scope.telling.series[tooltipItem.datasetIndex];
                                        if (seriekey) {
                                            return `${alleseries[seriekey]?.tooltiplabel || alleseries[seriekey]?.title || seriekey}: ${tooltipItem.yLabel}`;
                                        }
                                        return false;
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    //    maxBarThickness: 40,
                                    ticks: {
                                        /* maxRotation: 45,
                                         minRotation: 45,*/
                                        padding: 5,
                                        fontSize: 10,
                                        major: {
                                            enabled: true,
                                            fontStyle: 'bold',
                                            fontColor: 'red'
                                        }
                                    }
                                }],
                                yAxes: [{
                                    id: 'acties',
                                    display: 'auto',
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Actie'
                                    },
                                    stacked: true,
                                    weight: 4,
                                    ticks: {
                                        min: 0,
                                        precision: 0 //geen decimalen
                                    }
                                },
                                    {
                                        id: 'elementen',
                                        display: 'auto',
                                        gridLines: {
                                            color: 'rgba(0,255,0,0.1)'
                                        },
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Type',
                                            fontColor: '#348534' //passend bij de kleuren
                                        },
                                        stacked: true,
                                        weight: 3,
                                        ticks: {
                                            min: 0,
                                            precision: 0 //geen decimalen
                                        }
                                    },
                                    {
                                        id: 'norm',
                                        display: 'auto',
                                        gridLines: {
                                            color: 'rgba(255,0,0,0.1)'
                                        },
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Norm',
                                            fontColor: '#F00' //passend bij de kleuren
                                        },
                                        stacked: true,
                                        weight: 2,
                                        ticks: {
                                            min: 0,
                                            precision: 0 //geen decimalen
                                        }
                                    }
                                ]
                            }
                        },
                        donut: {
                            responsive: true,
                            legend: {
                                display: false //alleen voor testen
                            },
                            tooltips: {
                                callbacks: {
                                    label: (tooltipItem, data) => {
                                        log.debug(tooltipItem, data, $scope.telling.donutseries);
                                        let seriekey = $scope.telling.donutseries[tooltipItem.index];
                                        if (seriekey) {
                                            return `${alleseries[seriekey]?.tooltiplabel || alleseries[seriekey]?.title || seriekey}: ${data.datasets[0].data[tooltipItem.index]}`;
                                        }
                                        return false;
                                    }
                                }
                            },
                        }
                    };
                    $scope.users = [];

                    //model
                    $scope.model = {
                        state: location.hostname === 'localhost' ? 'live' : "alles",
                        balken: {
                            acties: true,
                            elementen: true,
                            norm: true
                        }
                    }

                    ////// exposable api
                    $scope.api = {
                        open() {
                            if (!(api.userdata && api.userdata.meekijker)) {
                                return; //gaan we niet doen
                            }
                            log.debug(`Dashboard open`);
                            if (!$scope._open) {
                                $scope._open = true;
                                api.send('dashboard', 'subscribe'); //die doet de rest
                                // $scope.updateBalken(); //alle knoppen goed
                            }
                        },

                        close() {
                            if ($scope._open) {
                                $scope._open = false;
                                if (_rebuildTimer) {
                                    //die hoeft niet meer dus
                                    $timeout.cancel(_rebuildTimer);
                                    _rebuildTimer = null;
                                }
                                setDetail(null); //unsubscribe op bepaald model
                                api.send('dashboard', 'unsubscribe'); //weg ermee
                            }
                        }
                    };

                    $scope.$on('chart-create', (_e, chart) => {
                        log.debug(`create chart`, chart.config.type);
                        //bewaar de chart voor redraw etc
                        if (chart.config.type === 'bar') {
                            _barchart = chart;
                        } else if (chart.config.type === 'doughnut') {
                            _donutchart = chart;
                        }
                        if (!$scope.telling?.models?.length) {
                            //bouwen dus
                            _rebuildChart();
                        }
                    });

                    $scope.$on('api.notify.registratieVerzoek', _ => {
                        //er is blijkbaar een hickup geweest. We gaan opnieuw registreren
                        if ($scope._open) {
                            api.send('dashboard', 'subscribe'); //opnieuw subscriben
                        }
                    });


                    $scope.$on('$destroy', _ => {
                        //unsubscribe etc
                        if ($scope._open) {
                            $scope.api.close(); //handel ook unsubscribe etc af
                        }
                    });


                    ////////////////////////// Binnenkomende data ////////////////////////
                    $scope.$on('api.notify.dashboardlog', (_e, data) => {
                        log.debug(`Dashboard`, data);
                        _acties = data.acties;
                        _types = data.types;
                        if (data.bijSubscribe) {
                            //meteen uitvoeren
                            _rebuildChart();
                        } else {
                            planRebuild(); //niet direct, maar straks
                        }

                    });

                    $scope.$on('api.notify.dashboardlive', (_e, live) => {
                        //update onze live-array
                        _live = live; //gewoon bewaren
                        //chart of niet, we plannen een rebuild
                        if ( $scope._open ) {
                            planRebuild();
                        }
                    });

                    /**
                     * Telling van een model is bijgewerkt
                     */
                    $scope.$on('api.notify.dashboardtelling', (_e, data) => {
                        if ($scope.models[data.model]) {
                            $scope.models[data.model].normtelling = data.telling;
                        }
                        planRebuild();
                    });

                    /**
                     * Zoek info voor alle modellen. Bevat ook de laatst opgelsagen normtelling van de modellen. Async, dus je moet dit even verpakken in een $q
                     * @param models
                     */
                    async function getModelinfo(models) {
                        //goed, moeten we iets ophalen?
                        models = models.filter(m => !($scope.models[m]));
                        if (models.length) {
                            let modellen = [];
                            try {
                                modellen = await api.send('dashboard', 'modelinfo', {modellen: models});
                            } catch (e) {
                                log.error(e);
                            }
                            for (let model of Object.keys(modellen)) {
                                $scope.models[model] = modellen[model];
                            }
                        }
                        log.debug(`klaar met getModelInfo`);
                    }

                    /////////////// Events ////////////////
                    $scope.stateChange = function () {
                        log.debug(`Statechange. Data halen?`, $scope.model.state);
                        //herbouwen
                        _rebuildChart();
                    }

                    /**
                     * Zet een serie uit of aan
                     * @param seriedata De data uit balken.balk.serie
                     */
                    $scope.toggleSerie = function (seriedata) {
                        seriedata.hidden = !seriedata.hidden; //onze data
                        //zijn er series die deze volgen?
                        for (let volger of seriedata.volgers) {
                            volger.hidden = seriedata.hidden;
                        }
                        //en de override aanpassen:
                        $scope.updateBalken();
                    }
                    /**
                     * Even bij wijzigen balken of acties/elementen qua zichtbaarheid. We rekenen de zichtbaarheid opnieuw uit, en voeren dat meteen door voor de donut
                     */
                    $scope.updateBalken = function () {
                        log.debug('updateBalken');
                        $scope.telling.donutdata = [];
                        $scope.telling.donutlabels = [];
                        $scope.telling.donutcolors = [];
                        $scope.telling.donutseries = [];
                        for (let balkkey of Object.keys(balken)) {
                            let balk = balken[balkkey];
                            for (let seriekey of Object.keys(balk)) {
                                let serie = balk[seriekey];
                                serie.override.hidden = !($scope.model.balken[balkkey] && (!serie.hidden));
                                //verborgen series niet in donut, net als series die er expliciet niet ingaan
                                if ((!serie.override.hidden) && (!balk[seriekey].nietindonut)) {
                                    $scope.telling.donutseries.push(seriekey);
                                    $scope.telling.donutlabels.push(seriekey);
                                    $scope.telling.donutcolors.push(serie.color);
                                    $scope.telling.donutdata.push($scope.telling.totalen[seriekey]);
                                }
                            }
                        }
                        checkDataLeeg();
                        updateDonut();
                    }

                    /**
                     * Laat canvascontroller weten dat er meegekeken moet worden met het detail
                     */
                    $scope.meekijken = function () {
                        if ($scope.detail) {
                            $scope.laatMeekijken({model: $scope.detail});
                            $scope.api.close(); //wij dicht
                        }
                    }

                    $scope.chartClick = function (evt) {
                        log.debug(`click`, evt, _barchart);
                        if ($scope.detail) {
                            return; //dan geen klikafhandeling
                        }
                        let x_as = _barchart.scales['x-axis-0'];
                        let rekenX = evt.offsetX; //dat is de standaard
                        if (evt.offsetY > x_as.top) {
                            log.debug('LABEL');
                            //we kijken naar label 0
                            /*
                            Als we op een schuin label klikken, moet de x van die klik
                            gecorrigeerd worden. De dx die we zoeken maakt van de klik-x
                            de x zoals hij geweest was als we bij de basis van het label (bij de grafiek)
                            hadden geklikt. In de driehoek tussen die basis, het klikpunt en het punt op de rechte lijnen vanaf klik en vanaf basis is dit de aanliggende zijde
                            van de hoek van het label. Overstaan is dan de dy tussen labelbasis en klik.
                            dx = (offsetY - LabelY) /  tan(rotation). Rotation is in radialen
                             */
                            let label = x_as._labelItems[0];
                            let dy = evt.offsetY - label.y;
                            let rekenhoek = label.rotation; //in radialen
                            let dx = dy / Math.abs(Math.tan(rekenhoek));
                            rekenX += dx; //de correctie
                            log.debug(`dy: ${dy} rot: ${label.rotation} Rekenhoek: ${rekenhoek} tan: ${Math.tan(rekenhoek)} dx: ${dx} OffsetX: ${evt.offsetX} rekenX: ${rekenX} index: `);
                        }
                        let tick = x_as.getValueForPixel(rekenX);
                        log.debug(`KLIK OP`, tick, $scope.telling.labels[tick], $scope.telling.chartmodels[tick]);
                        if ($scope.telling.chartmodels[tick]) {
                            setDetail($scope.telling.chartmodels[tick])
                        }
                    }

                    /**
                     * Stel het bekijken van een detail in, of juist geen een
                     * @param {string | null} modelID
                     */
                    function setDetail(modelID) {
                        $scope.detail = modelID;
                        $scope.detailImg = null;
                        if (modelID) {
                            let url = $scope.models[modelID].imgUrl;
                            if (url) {
                                $scope.detailImg = `${api.getUrl()}/${url}`;
                            }
                        }
                        sihwWait(
                            _rebuildChart()); //opnieuw bouwen. Layout wijzigt ook vanzelf
                    }

                    /**
                     * Zap het detail
                     * @param richting
                     */
                    $scope.zapDetail = function (richting) {
                        if (!$scope.detail) {
                            return; //klopt niet
                        }
                        let i = $scope.telling.models.indexOf($scope.detail);
                        if (i === -1) {
                            //vreemd, weg ermee
                            setDetail(null);
                            return;
                        }
                        i += richting;
                        if (i < 0) {
                            i = $scope.telling.models.length - 1; //rond
                        } else if (i >= $scope.telling.models.length) {
                            i = 0;
                        }
                        log.debug(`setDetail`, i, $scope.telling.models[i]);
                        setDetail($scope.telling.models[i]);
                    }

                    /**
                     * Skip detail naar volgende user
                     * @param richting
                     */
                    $scope.zapDetailUser = function (richting) {
                        if (!$scope.detail) {
                            return; //klopt niet
                        }
                        let i = $scope.telling.models.indexOf($scope.detail);
                        if (i === -1) {
                            //vreemd, weg ermee
                            setDetail(null);
                            return;
                        }
                        let user = $scope.models[$scope.detail].user;
                        let newindex = i;
                        do {
                            newindex += richting;
                            if (newindex < 0) {
                                newindex = $scope.telling.models.length - 1; //rond
                            } else if (newindex >= $scope.telling.models.length) {
                                newindex = 0;
                            }
                            let modelid = $scope.telling.models[newindex];
                            let modelinfo = $scope.models[modelid];

                            if (modelinfo && modelinfo.user !== user) {
                                setDetail(modelid);
                                break;
                            }
                        } while (newindex !== i);
                    }

                    /**
                     * Sluit het detailoverzicht weer
                     */
                    $scope.sluitDetail = function () {
                        setDetail(null);
                    }


                    //check of alles leeg is
                    function checkDataLeeg() {
                        $scope.dataLeeg = $scope.telling.chartmodels.length === 0 || $scope.telling.override.every(s => s.hidden);
                    }

                    ////// data ///////////////////
                    /**
                     * We doe rebuild uitgesteld, zodat we niet elke seconde bezig blijven.
                     */
                    function planRebuild() {
                        //speciaal: er is nu niets zchtbaar
                        //dan doen we het meteen
                        if (!$scope.telling?.models?.length) {
                            _rebuildChart();
                        } else if (!_rebuildTimer) {
                            _rebuildTimer = $timeout(_rebuildChart, rebuildtimeout);
                        }
                        //anders is hij er al en komt het vanzef
                    }

                    /**
                     * Hertel en herteken. Hoewel niet superefficient doen we gewoon een hele recount op het moment dat er data wijzigt of er naar een detail gegaan wordt. Hierdoor houden we deze kennis mooi centraal
                     * @private
                     */
                    function _rebuildChart() {
                        if (_rebuildTimer) {
                            //die hoeft niet meer dus
                            $timeout.cancel(_rebuildTimer);
                            _rebuildTimer = null;
                        }

                        $scope.updateFlag = Date.now(); //voor update van het image
                        let per_model = {}; //key: modelid, values een hash met serienaam (dus van actie of element) plus count
                        $scope.telling.totalen = {}; //key: serienaam, value de count
                        let elementcache = {}; //om het zoeken op element sneller te maken
                        //loop over de laatst binnengekregen actionlogdata:
                        for (let d of _acties) {
                            if ((!$scope.detail) && $scope.model.state === 'live' && (!_live.includes(d.model_id))) {
                                continue; //direct skippen. Niet efficient, maar totalen kloppen
                            }
                            if (d.model_id && d.action && d.count) {
                                let actie = 'rest'; //default
                                for (let actietype of Object.keys(_actietypes)) {
                                    if (_actietypes[actietype].includes(d.action)) {
                                        //juiste actie
                                        actie = actietype;
                                        break;
                                    }
                                }
                                if (actie === 'rest') {
                                    log.debug(`restactie: ${d.action}`);
                                }
                                (per_model[d.model_id] ?? (per_model[d.model_id] = {}))[actie] = d.count;
                                $scope.telling.totalen[actie] = ($scope.telling.totalen[actie] ?? 0) + d.count;
                            }
                        }

                        for (let d of _types) {
                            if ((!$scope.detail) && $scope.model.state === 'live' && (!_live.includes(d.model_id))) {
                                continue; //direct skippen. Niet efficient, maar totalen kloppen
                            }
                            if (d.model_id && d.targettype && d.count) {
                                let element = 'rest'; //default
                                //welk element doen we?
                                if (elementcache[d.targettype]) {
                                    element = elementcache[d.targettype];
                                } else {
                                    //zoeken
                                    for (let elementtype of Object.keys(_elementtypes)) {
                                        let types = _elementtypes[elementtype] || [elementtype]; //als dat niet gegeven is, dan is de key zelf wat we zoeken (meestal zelfs)
                                        if (types.some(type => elementService.isSubtypeOf(d.targettype, type))) {
                                            //gevonden
                                            element = elementtype;
                                            break;
                                        }
                                    }
                                }
                                //cache
                                elementcache[d.targettype] = element;
                                if (element === 'rest') {
                                    log.debug(`restelement: ${d.targettype}`);

                                    //skippen
                                    continue;
                                }
                                (per_model[d.model_id] ?? (per_model[d.model_id] = {}))[element] = d.count;
                                $scope.telling.totalen[element] = ($scope.telling.totalen[element] ?? 0) + d.count;
                            }
                        }

                        log.debug("per_model", per_model);

                        log.debug(`Ophalen modelinfo`);
                        return $q.when(getModelinfo(Object.keys(per_model))).then(() => {
                            log.debug(`klaar met ophalen modelinfo`);

                            //normtelling - kan nu de info er is
                            for (let model of Object.keys(per_model)) {
                                for (let key of Object.keys(balken.norm)) {
                                    //op 0 zetten
                                    per_model[model][key] = 0;
                                }
                                //model kan missen in huidige data - als het model niet meer bestaat. Vandaar het vraagteken
                                if ($scope.models[model]?.normtelling) {
                                    // log.warn(`Normtelling`, model, $scope.models[model]?.normtelling);
                                    let nt = $scope.models[model].normtelling;
                                    log.warn(nt);
                                    for (let key of Object.keys(nt.model)) {
                                        let goedkey = `${key}_goed`;
                                        let foutkey = `${key}_fout`;
                                        let missendkey = `${key}_missend`;

                                        let verwacht = nt.norm[key] || 0;
                                        let aantal = nt.model[key].aantal || 0;
                                        let fout = nt.model[key].fouten || 0;


                                        //aantal goede is aantal min fouten
                                        let goed = aantal - fout;

                                        let missend = 0;
                                        if (aantal > verwacht) {
                                            //dan zijn er fouten
                                            if (!fout) {
                                                fout = aantal - verwacht; //als er al fouten zijn, heeft de normcheck gedraaid en zit het aantal fout daarin
                                            }
                                            goed = aantal - fout;
                                        } else {
                                            missend = verwacht - aantal;
                                        }

                                        if (goedkey in per_model[model]) {
                                            per_model[model][goedkey] = goed;
                                            $scope.telling.totalen[goedkey] = ($scope.telling.totalen[goedkey] ?? 0) + goed;
                                        }
                                        if (foutkey in per_model[model]) {
                                            per_model[model][foutkey] = fout;
                                            $scope.telling.totalen[foutkey] = ($scope.telling.totalen[foutkey] ?? 0) + fout;
                                        }
                                        if (missendkey in per_model[model]) {
                                            per_model[model][missendkey] = missend;
                                            $scope.telling.totalen[missendkey] = ($scope.telling.totalen[missendkey] ?? 0) + missend;
                                        }
                                    }
                                }
                            }

                            //bouw de basis
                            //dat doen we hier, zodat we de series met 0 kunnen
                            //skippen
                            $scope.telling.series = [];
                            $scope.telling.colors = [];
                            $scope.telling.override = [];

                            for (let balkkey of Object.keys(balken)) {
                                for (let serie of Object.keys(balken[balkkey])) {
                                    // log.debug(serie, totalen[serie]);
                                    if ($scope.telling.totalen[serie]) {
                                        $scope.telling.series.push(serie);
                                        $scope.telling.colors.push(balken[balkkey][serie].color);
                                        $scope.telling.override.push(balken[balkkey][serie].override);
                                    }
                                }
                            }

                            $scope.telling.models = Object.keys(per_model);
                            //sorteren:
                            $scope.telling.models.sort((a, b) => {
                                let infoa = $scope.models[a];
                                let infob = $scope.models[b];
                                let labela = infoa ? `${infoa.user}: ${infoa.titel}` : '?';
                                let labelb = infob ? `${infob.user}: ${infob.titel}` : '?';
                                return labela.localeCompare(labelb);
                            });
                            //nu is het niet meer async, dus pas hier legen, anders zie je de chart steeds opnieuw opbouwen
                            $scope.telling.data = $scope.telling.series.map(_ => []); //voor elke serie een datarij
                            $scope.telling.chartmodels = []; //modellen in de chart (na filter)
                            $scope.telling.labels = [];
                            for (let model of $scope.telling.models/*.slice(0,10)*/) {
                                if ($scope.detail && model !== $scope.detail) {
                                    //deze beelden we niet af
                                    continue;
                                }
                                //alleen live?. Alleen bij overview relevant
                                if ((!$scope.detail) && $scope.model.state === 'live' && (!_live.includes(model))) {
                                    //niet relevant nu
                                    continue;
                                }
                                /*
                                beeld alleen modellen af die live zijn of waar een relevante actie te melden is
                                Model en rest telt niet mee
                                In detail alles laten zien
                                 */
                                if ((!$scope.detail) && (!(_live.includes(model) || $scope.telling.series.some(actie =>
                                    (!['model', 'rest'].includes(actie)) && !!per_model[model][actie]
                                )))) {
                                    //niets te doen en geen live model: weg
                                    continue;
                                }
                                $scope.telling.chartmodels.push(model); //deze wordt afgebeeld
                                let info = $scope.models[model];
                                let label = info ? `${info.user}: ${info.titel}` : '?';
                                $scope.telling.labels.push(label);
                                let serie = 0;
                                for (let actie of $scope.telling.series) {
                                    $scope.telling.data[serie].push(per_model[model][actie] ?? 0);
                                    serie++;
                                }
                            }

                            //breedte. Mooi ruim. Komt in een scroller
                            $scope.chartWidth = 400 + $scope.telling.labels.length * ($scope.opties.barwidth + 10);
                            log.debug("opgesplitst", per_model);
                            log.debug("telling", $scope.telling);
                            checkDataLeeg(); //is er nog iets?
                            //update de bar en forceer hertekenen
                            $scope.updateBalken(); //ook bijwerken
                            updateBar();

                        });
                    }

                    //helpers per chart
                    function updateBar() {
                        //update lijkt gewoon niet nodig?
                        return;
                        $timeout(() => {
                            if (_barchart && (!$scope.dataLeeg)) {
                                log.debug('update bar');
                                //   _barchart.update();
                            }
                        });
                    }

                    function updateDonut() {
                        //update lijkt gewoon niet nodig?
                        return;
                        $timeout(() => {
                            if (_donutchart && (!$scope.dataLeeg)) {
                                //      _donutchart.update();
                            }
                        });
                    }
                }
            ]
        }

    }
])
;
